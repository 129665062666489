<template>
  <div>
    <mdb-row>
      <mdb-col sm="12" md="12" lg="10" class="mx-auto">
        <mdb-card cascade>
          <div
            class="card-header-reversed color-primary-reversed z-depth-1 pl-2 pt-1 pb-1 d-flex justify-content-between align-items-center"
          >
            <div
              class="title-card-tec card-header-title color-primary-reversed"
              style="font-weight: 400"
            >
              Commerciaux
            </div>
            <mdb-btn
              class="float-right"
              tag="a"
              color="primary"
              icon="plus"
              size="sm"
              small
              @click="addOrEdit(null)"
            >
            </mdb-btn>
          </div>

          <mdb-card-body class="card-reversed" cascade v-loading="loading">
            <mdb-row>
              <mdb-col sm="12" md="4" class="mb-n4">
                <mdb-select
                  flipOnScroll
                  outline
                  label="Nombre par page"
                  @getValue="selected"
                  v-model="dataPagination.pagination.perPageOptions"
                />
              </mdb-col>
              <mdb-col sm="12" md="4" class="mb-n4"> </mdb-col>
              <mdb-col sm="12" md="4" class="mb-n3">
                <mdb-input
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  icon="search"
                  outline
                  type="text"
                  v-model="dataPagination.searchQuery"
                  placeholder="Rechercher"
                  aria-label="Search"
                />
              </mdb-col>
            </mdb-row>

            <mdb-tbl responsiveMd >
              <mdb-tbl-head>
                <tr class="color-normal-reversed">
                  <th v-for="column in data.columns" :key="column.name">
                    <span v-if="column.field === 'tax1'">
                      {{ configTs.tax1 }}
                    </span>
                    <span v-else-if="column.field === 'tax2'">
                      {{ configTs.tax2 }}
                    </span>
                    <span v-else>
                      {{ column.label }}
                    </span>
                  </th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body class="">
                <tr
                  class="tec-table-row color-normal-reversed"
                  v-for="row in queriedData"
                  :key="row.code_commercial"
                >
                  <td class="tec-table-cell text-align-right font-weight-bold">
                    {{ row.code_commercial }}
                  </td>
                  <td class="tec-table-cell text-align-right">
                    {{ row.lastname.toUpperCase() }} {{ row.firstname }}
                  </td>
                  <td class="tec-table-cell text-align-right">{{ row.phone }}</td>
                  <td class="tec-table-cell text-align-right">{{ row.email }}</td>
                  <td class="d-flex justify-content-end">
                    <el-dropdown trigger="click">
                      <span
                        class="el-dropdown-link"
                        style="cursor: pointer; font-weight: 500"
                      >
                        Actions<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                        <el-dropdown-item
                          @click.native="addOrEdit(row)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-edit"
                          >Modifier</el-dropdown-item
                        >
                        <el-dropdown-item
                          @click.native="deleteCommercial(row.id)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-delete"
                          >Supprimer</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <div v-if="empty">
              <div
                class="d-flex justify-content-around align-items-center color-normal-reversed"
                style="padding: 50px"
              >
                <img
                  :src="require('@/assets/svg/nodata.svg')"
                  alt=""
                  class="d-none flex-md-wrap d-md-block"
                  style="width: 25%"
                  srcset=""
                />
                <div>
                  <h5>Commencez dès maintenant à ajouter vos premiers commerciaux !</h5>
                  <p>
                    Pour ajouter un commercial, cliquez sur le bouton
                    <mdb-btn
                      class="z-depth-0"
                      style="cursor: inherit"
                      tag="a"
                      color="primary"
                      icon="plus"
                      size="sm"
                      small
                    ></mdb-btn>
                    en haut à votre droite.
                  </p>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <tec-pagination
                v-model="dataPagination.pagination.currentPage"
                :per-page="parseFloat(dataPagination.pagination.perPage)"
                :total="dataPagination.pagination.total"
                v-on:changePageEvent="loadCommercialBack"
              ></tec-pagination>
            </div>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbInput,
} from "mdbvue";
import TecPagination from "@/tba/components/PaginationUrl";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbInput,
    TecPagination,
  },
  props: {
    empty: {
      type: Boolean,
      default: false,
    },
    configTs: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataPagination: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    queriedData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    addOrEdit(data = null) {
      this.$emit("add_or_edit", data);
    },

    loadCommercialBack(e) {
      this.$emit("load_commercial_back", {
        page: e,
        perPage: this.dataPagination.pagination.perPage,
      });
    },

    deleteCommercial(id) {
      this.$emit("delete_commercial", id);
    },
  },
};
</script>
